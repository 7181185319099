import React from "react";
import b from "./SocialLinks.module.scss";

import classNames from 'classnames/bind';
import IconTelegram from "./icons/IconTelegram";
import IconVk from "./icons/IconVk";

let cx = classNames.bind(b);

const Icon = ({type}) => {
    switch (type) {
        case 'telegram':
            return <IconTelegram/>;
        case 'vk':
            return <IconVk/>;
        default:
            return null;
    }
}

export const SocialLink = (props) => {
    const {type, link} = props;

    return (
        <div className={cx(props.className, {
            item: true,
            [`${type}`]: true,
        })}>
            <a className="text-hide" target="_blank" href={link} rel="noreferrer">
                <Icon type={type}/>
            </a>
        </div>
    );
}
