import React from "react";
import b from './Ticker.module.scss'
import {useScroll, useTransform, motion} from "framer-motion";
import {Headline} from "../../components/headline/Headline";
import {ReactComponent as StarSmall} from './assets/star-small.svg';
import {ReactComponent as StarMedium} from './assets/star.svg';

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const Star = (props) => {
    const {color = "red"} = props;
    return (
        <div className={cx(props.className, {
            starContainer: true,
            ['color-' + color]: true
        })}>
            <StarSmall className={`${b.star} hide-for-medium`}></StarSmall>
            <StarMedium className={`${b.star} show-for-medium`}></StarMedium>
        </div>
    )
}

export const Ticker = () => {
    const {scrollYProgress} = useScroll();
    const x = useTransform(scrollYProgress, [0, 1], [-250, -1000]);
    return (
        <div className={b.container}>
            <div className={b.trackContainer}>
                <motion.div
                    className={b.track}
                    style={{x}}
                >
                    <Headline className={b.headline}>
                        Развивай клуб с каждой ставкой <Star/>
                        Развивай клуб с каждой ставкой <Star color="blue"></Star>
                        Развивай клуб с каждой ставкой <Star/>
                        Развивай клуб с каждой ставкой <Star color="blue"></Star>
                        Развивай клуб с каждой ставкой <Star/>
                        Развивай клуб с каждой ставкой <Star color="blue"></Star>
                        Развивай клуб с каждой ставкой <Star/>
                        Развивай клуб с каждой ставкой <Star color="blue"></Star>
                    </Headline>
                </motion.div>
            </div>
        </div>
    );
}
