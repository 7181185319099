import React from "react";
import b from './VotingForm.module.scss'
import {Headline} from "../../components/headline/Headline";
import {QuestionForm} from "./question/QuestionForm";
import {Modal} from "./modal/Modal";
import {ModalSuccess} from "./modal/ModalSuccess";

export const VotingForm = (props) => {
    return (
        <div className={b.container} {...props}>
            <Headline className={b.headline}>
                ГОЛОСОВАНИЕ WINLINE
            </Headline>
            <div className={b.subHeadline}>
                Выбери, во что вложить средства из фонда клуба
            </div>
            <QuestionForm/>
            <Modal/>
            <ModalSuccess/>
        </div>
    );
}
