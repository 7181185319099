import React, {useCallback, useState} from "react";
import b from './Modal.module.scss';
import {Headline} from "../../../components/headline/Headline";
import {Button} from "../../../components/button/Button";
import {useDispatch, useSelector} from "react-redux";
import {closeSuccessModal, getIsAlreadyVoted, getModalSuccessStatus} from "../../../store/survey";
import {useMotionValueEvent, useScroll} from "framer-motion";
import ReactModal from "react-modal";
import IconClose from "./assets/IconClose";

export const ModalSuccess = () => {
    const isAlreadyVoted = useSelector(getIsAlreadyVoted);
    const dispatch = useDispatch();
    const isModalOpen = useSelector(getModalSuccessStatus);
    const [scrollTop, setScrollTop] = useState(0);
    const {scrollY} = useScroll()
    useMotionValueEvent(scrollY, "change", (latest) => {
        if (isModalOpen === false) {
            setScrollTop(latest);
        }
    })
    const handleOnAfterOpen = useCallback(() => {
        document.body.style.top = `-${scrollTop}px`;
    }, [scrollTop]);
    const handleOnAfterClose = useCallback(() => {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY) * -1);
    }, []);
    const handleRequestClose = () => {
        dispatch(closeSuccessModal())
    };
    return (
        <ReactModal
            isOpen={isModalOpen}
            onAfterOpen={handleOnAfterOpen}
            onAfterClose={handleOnAfterClose}
            onRequestClose={handleRequestClose}
            closeTimeoutMS={420}
            contentLabel="Форма успеха"
            portalClassName={b.portal}
            overlayClassName={{
                base: b.overlay,
                afterOpen: b.overlayAfterOpen,
                beforeClose: b.overlayBeforeClose
            }}
            id={"some-id"}
            className={b.content}
            bodyOpenClassName={b.bodyOpen}
            htmlOpenClassName={b.htmlOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            role={"dialog"}
            preventScroll={true}
            parentSelector={() => document.querySelector('#modal-root')}
        >
            <button type="button" onClick={handleRequestClose} className={b.closeButton}>
                <IconClose/>
            </button>
            <div className={b.container}>
                <div className={b.verticalCenter}>
                    <Headline dark={true} className={b.headline}>
                        Ваш голос принят
                    </Headline>
                    <div className={b.subHeadlineSuccess}>
                        {isAlreadyVoted
                            ? 'Ранее вы уже отдали свой голос в опросе. Благодарим за участие!'
                            : 'Принять участие в опросе можно один раз. Спасибо за ваш голос!'}
                    </div>
                </div>
                <div className={b.buttonContainer}>
                    <Button onClick={handleRequestClose} type="button" expanded={true}>
                        К результатам
                    </Button>
                </div>
            </div>
        </ReactModal>
    );
}
