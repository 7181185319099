import {pipe, prop} from "ramda";
import {getSurveyState} from "../moduleStateSelectors";

export const getIsFetchingResults = pipe(getSurveyState, prop('isFetchingResults'));
export const getIsFetchingVariant = pipe(getSurveyState, prop('isFetchingVariant'));
export const getIsFetchingSignature = pipe(getSurveyState, prop('isFetchingSignature'));
export const getIsFetchingVerifyCode = pipe(getSurveyState, prop('isFetchingVerifyCode'));
export const getIsFetchingVote = pipe(getSurveyState, prop('isFetchingVote'));
export const getIsHasBeenOpened = pipe(getSurveyState, prop('isFormHasBeenOpened'));
export const getResults = pipe(getSurveyState, prop('entities'));
export const getSelectedVariant = pipe(getSurveyState, prop('selectedVariant'));
export const getVote = pipe(getSurveyState, prop('vote'));
export const getVariant = pipe(getSurveyState, prop('variant'));
export const getSignature = pipe(getSurveyState, prop('signature'));
export const getIsCodeVerified = pipe(getSurveyState, prop('isCodeVerified'));
export const getIsVoted = pipe(getSurveyState, prop('isVoted'));
export const getIsAlreadyVoted = pipe(getSurveyState, prop('isAlreadyVoted'));
export const getModalFormStatus = pipe(getSurveyState, prop('isFormModalOpen'));
export const getModalSuccessStatus = pipe(getSurveyState, prop('isSuccessModalOpen'));
export const getIsUserVerified = pipe(getSurveyState, prop('userVerified'));
export const getError = pipe(getSurveyState, prop('error'));
