import React from "react";
import {motion} from "framer-motion";
import b from "./Goals.module.scss"
import {Headline} from "../../components/headline/Headline";
import n01 from './assets/01.svg'
import n02 from './assets/02.svg'
import n03 from './assets/03.svg'
import cardImage1Medium from "./assets/cards/medium/card-image-1.png";
import cardImage1Medium2x from "./assets/cards/medium/card-image-1@2x.png";
import cardImage1Large from "./assets/cards/large/card-image-1.png";
import cardImage1Large2x from "./assets/cards/large/card-image-1@2x.png";
import cardImage2Medium from "./assets/cards/medium/card-image-2.png";
import cardImage2Medium2x from "./assets/cards/medium/card-image-2@2x.png";
import cardImage2Large from "./assets/cards/large/card-image-2.png";
import cardImage2Large2x from "./assets/cards/large/card-image-2@2x.png";
import cardImage3Medium from "./assets/cards/medium/card-image-3.png";
import cardImage3Medium2x from "./assets/cards/medium/card-image-3@2x.png";
import cardImage3Large from "./assets/cards/large/card-image-3.png";
import cardImage3Large2x from "./assets/cards/large/card-image-3@2x.png";

import classNames from 'classnames/bind';

let cx = classNames.bind(b);

const cardVariants = {
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            duration: 1
        }
    },
    hidden: {
        scale: 0.8,
        opacity: 0
    }
};

const contentVariants = {
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            delay: 0.1
        }
    },
    hidden: {opacity: 0}
};

const imageVariants = {
    visible: {
        opacity: 1,
        transition: {
            duration: 2,
            delay: 0.4
        }
    },
    hidden: {opacity: 0}
};

const Card = (props) => {
    const {content, image} = props;
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{once: true}}
            variants={cardVariants}
            className={cx(props.className, {
                cardContainer: true,
            })}
        >
            <div className={b.card}>
                <motion.div
                    variants={contentVariants}
                    className={cx({
                        cardContent: true,
                    })}>
                    {content}
                </motion.div>
                <motion.div
                    variants={imageVariants}
                    className={cx({
                        cardImage: true,
                    })}>
                    {image}
                </motion.div>
            </div>
        </motion.div>
    )
}

export const Goals = () => {
    return (
        <div className={b.container}>
            <div className={`${b.header} row`}>
                <div className="column small-12 medium-8 large-7">
                    <Headline className={b.headline}>
                        Обзор призового фонда клуба
                    </Headline>
                </div>
                <div className="column small-12 medium-7 medium-offset-5 large-5 large-offset-0 align-self-bottom">
                    <div className={b.headerText}>
                        Тебе предстоит выбрать из нескольких опций, как именно потратить собранные средства и поддержать
                        клуб. Ниже представлены варианты и их краткое описание.
                    </div>
                </div>
                <div className={b.coinsContainer}>
                    <span className={`${b.coin} ${b.coin1}`}></span>
                    <span className={`${b.coin} ${b.coin2}`}></span>
                    <span className={`${b.coin} ${b.coin3}`}></span>
                </div>
            </div>
            <div className={`${b.cards} row`}>
                <div className="column small-12 medium-12 large-12">
                    <Card className={b.cardFirst}
                          content={
                              <>
                                  <div className={`${b.cardNumber} text-hide`}>
                                      01 <img src={n01} title="01" alt="01" draggable="false"/>
                                  </div>
                                  <Headline className={b.cardTitle}>
                                      Баскетбольная вечеринка на корабле
                                  </Headline>
                                  <div className={b.cardText}>
                                      После завершения сезона собираемся вместе с командой, комментаторами, блогерами,
                                      болельщиками ЦСКА и устраиваем крутые баскетбольные пикники на Москве-реке. Прямо
                                      на корабле можно будет поиграть в баскетбол, пересмотреть легендарные матчи,
                                      прикоснуться к истории команды и просто классно провести время в год столетия!
                                  </div>
                              </>
                          }
                          image={
                              <>
                                  <img
                                      className="hide-for-large"
                                      src={cardImage1Medium} srcSet={`${cardImage1Medium2x} 2x`}
                                      draggable="false"
                                      alt=""
                                  />
                                  <img
                                      className="show-for-large"
                                      src={cardImage1Large} srcSet={`${cardImage1Large2x} 2x`}
                                      draggable="false"
                                      alt=""
                                  />
                              </>
                          }>
                    </Card>
                </div>
                <div className="column small-12 medium-12 large-12">
                    <Card className={b.cardSecond} content={
                        <>
                            <div className={`${b.cardNumber} text-hide`}>
                                02 <img src={n02} title="02" alt="02" draggable="false"/>
                            </div>
                            <Headline className={b.cardTitle}>
                                Музей клуба в москве
                            </Headline>
                            <div className={b.cardText}>
                                Если арена — это баскетбольный дом любой команды, то музей — это энциклопедия клубной
                                истории, архив наград и достижений, и, что важнее, место, где живёт стремление к победам
                                и дух покорения новых вершин. ЦСКА должен обрести новый музей, и болельщики в этом
                                помогут!
                            </div>
                        </>
                    }
                          image={
                              <>
                                  <img
                                      className="hide-for-large"
                                      src={cardImage2Medium} srcSet={`${cardImage2Medium2x} 2x`}
                                      draggable="false"
                                      alt=""
                                  />
                                  <img
                                      className="show-for-large"
                                      src={cardImage2Large} srcSet={`${cardImage2Large2x} 2x`}
                                      draggable="false"
                                      alt=""
                                  />
                              </>
                          }>
                    </Card>
                </div>
                <div className="column small-12 medium-12 large-12">
                    <Card className={b.cardThird} content={
                        <>
                            <div className={`${b.cardNumber} text-hide`}>
                                03 <img src={n03} title="03" alt="03" draggable="false"/>
                            </div>
                            <Headline className={b.cardTitle}>
                                10 комфортных мест для болельщиков
                            </Headline>
                            <div className={b.cardText}>
                                Любая арена, где играет ЦСКА, хороша, но у вас есть возможность сделать лучше
                                отдельно взятый сектор! После установки кресел повышенной комфортности, любой
                                болельщик сможет провести два часа на арене с особым наслаждением.
                            </div>
                        </>
                    } image={
                        <>
                            <img
                                className="hide-for-large"
                                src={cardImage3Medium} srcSet={`${cardImage3Medium2x} 2x`}
                                draggable="false"
                                alt=""
                            />
                            <img
                                className="show-for-large"
                                src={cardImage3Large} srcSet={`${cardImage3Large2x} 2x`}
                                draggable="false"
                                alt=""
                            />
                        </>
                    }>
                    </Card>
                </div>
            </div>
        </div>
    );
};
