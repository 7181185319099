import React from 'react';
import b from './Footer.module.scss';
import {Highlight} from "../../components/highlight/Highlight";
import {Headline} from "../../components/headline/Headline";
import logo from './assets/logo-footer-winline.png';
import logo2x from './assets/logo-footer-winline@2x.png';
import {SocialLinks} from "../../features/social-links/SocialLinkList";
import {SocialLink} from "../../features/social-links/SocialLinkItem";

export const Footer = () => {
    return (
        <footer className={b.footer}>
            <div className="row">
                <div className="column large-11">
                    <Headline className={b.footerHeadline}>
                        Принимай участие <Highlight>в&nbsp;голосовании</Highlight> в наших соцсетях
                    </Headline>
                </div>
            </div>
            <div className="row">
                <div className="column small-12 medium-7 large-5">
                    <div className={b.footerNote}>
                        <p>Все голоса будут суммироваться с текущими результатами опроса на сайте</p>
                    </div>
                </div>
            </div>
            <div className="row small-m-t-25 medium-m-t-30 m-t-50">
                <div className="column small-12 medium-4 large-3 align-center">
                    <div className={b.footerLogo}>
                        <a target="_blank" href="https://winline.ru" rel="noreferrer">
                            <img
                                src={logo}
                                srcSet={`${logo2x} 2x`}
                                width="200"
                                height="48"
                                draggable="false"
                                alt="Winline Logo"
                            />
                        </a>
                    </div>
                </div>
                <div className="column small-12 medium-offset-2 medium-6 large-offset-5 large-4 small-m-t-40">
                    <SocialLinks className="align-right" theme="light">
                        <SocialLink type="telegram" link="https://t.me/winline_channel"/>
                        <SocialLink type="vk" link="https://vk.com/winline"/>
                    </SocialLinks>
                </div>
            </div>
            <div className="row small-m-t-65 medium-m-t-65 m-t-80">
                <div className="column small-12 large-12">
                    <div className={b.footerLinksContainer}>
                        <div className="row">
                            <div className="column small-12 medium-5 large-6">
                                <div className={b.footerLinks}>
                                    <div className={b.footerLink}>
                                        Поддержка – <a href="mailto:help@winline-vote.ru">help@winline-vote.ru</a>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="column small-12 medium-7 large-6">*/}
                            {/*    <div className={`${b.footerLinks} align-right`}>*/}
                            {/*        <div className={b.footerLink}>*/}
                            {/*            <a href="#use-conditions">Условия использования</a>*/}
                            {/*        </div>*/}
                            {/*        <div className={`${b.footerLink} no-margin`}>*/}
                            {/*            <a href="#policy">Политика конфиденциальности</a>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
