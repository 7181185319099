import React, {useEffect, useState} from "react";
import b from './Input.module.scss'
import {isEmpty, isNil, isNotNil} from "ramda";
import {useField, useFormikContext} from "formik";
import classNames from 'classnames/bind';
import OTPInput from 'react-otp-input';
import {Button} from "../button/Button";
import loading from "./assets/loading.svg"
import {ReactComponent as IconSuccess} from './assets/success.svg';
import {useDispatch, useSelector} from "react-redux";
import {
    changeVoteFormField,
    getError,
    getIsCodeVerified,
    getIsFetchingSignature,
    getIsFetchingVerifyCode,
    getSignature,
    requestSignature,
    requestVerifyCode
} from "../../store/survey";

let cx = classNames.bind(b);

export const InputEmail = (props) => {
    const {
        placeholder = '',
        ...inputProps
    } = props;
    const [field, meta, helpers] = useField(props);
    const {values, setFieldValue} = useFormikContext();
    const [isOtpFilled, setOtpFilled] = useState(false);
    const dispatch = useDispatch();
    const isFetchingSignature = useSelector(getIsFetchingSignature);
    const signature = useSelector(getSignature);
    const isFetchingVerifyCode = useSelector(getIsFetchingVerifyCode);
    const isCodeVerified = useSelector(getIsCodeVerified);
    const errors = useSelector(getError);
    const handleButtonRequestSignature = () => {
        // Запрашиваем сигнатуру
        dispatch(requestSignature(field.value));
    }
    const handleOnChangeOtp = (value) => {
        dispatch(changeVoteFormField({name: 'otp', value: value || null}));
        setFieldValue("otp", value);
    }
    const handleButtonVerifyCode = () => {
        dispatch(requestVerifyCode({
            code: values.otp,
            token: values.token
        }));
    }
    const handeOnChange = e => {
        e.preventDefault();
        const {value} = e.target;
        setFieldValue("email", value);
        helpers.setTouched(true, false)
    }
    useEffect(() => {
        dispatch(changeVoteFormField({name: field.name, value: field.value || ''}));
    }, [dispatch, field.name, field.value])
    useEffect(() => {
        if (isNotNil(signature)) {
            setFieldValue('token', signature);
            dispatch(changeVoteFormField({name: 'token', value: signature || null}));
        }
    }, [dispatch, setFieldValue, signature]);
    useEffect(() => {
        if (values.otp.length === 4) {
            setOtpFilled(true);
        } else {
            setOtpFilled(false);
        }
        dispatch(changeVoteFormField({name: 'otp', value: values.otp || ''}));
    }, [dispatch, values.otp]);
    useEffect(() => {
        if (isCodeVerified) {
            setFieldValue('emailVerified', true);
            dispatch(changeVoteFormField({name: 'emailVerified', value: true}));
        }
    }, [dispatch, isCodeVerified, setFieldValue]);
    return (
        <div className={cx({
            container: true
        })}>
            <div className={cx({
                row: true
            })}>
                <div className={cx({
                    column: true,
                })}>
                    <div className={cx(props.className, {
                        group: true,
                        up: !isEmpty(field.value),
                        errors: meta.touched && meta.error
                    })}>
                        <input
                            className={cx({
                                input: true
                            })}
                            type="email"
                            readOnly={isNotNil(values.token)}
                            {...field}
                            {...inputProps}
                            onChange={handeOnChange}
                        />
                        {placeholder && (
                            <span className={cx({
                                placeholder: true
                            })}>
                        {placeholder}
                    </span>
                        )}
                    </div>
                    {meta.touched && meta.error ? (
                        <div className={b.error}>{meta.error}</div>
                    ) : null}
                </div>
                <div className={cx({
                    column: true,
                })}>
                    {isNil(values.token) ? (
                        <Button
                            onClick={handleButtonRequestSignature}
                            disabled={meta.touched === false || isNotNil(meta.error) || isFetchingSignature}
                            type="submit"
                            size="small"
                            expanded={true}
                        >
                            {isFetchingSignature
                                ? (
                                    <img
                                        className={cx({
                                            buttonLoader: true,
                                        })}
                                        src={loading} alt=""
                                    />
                                ) : (
                                    "Подтвердить почту"
                                )}
                        </Button>
                    ) : (
                        <div className={cx({
                            otpContainer: true,
                            errors: errors?.code === 'verification_code_invalid',
                        })}>
                            <div className={cx({
                                otp: true,
                            })}>
                                <OTPInput
                                    value={values.otp}
                                    onChange={handleOnChangeOtp}
                                    numInputs={4}
                                    inputType="number"
                                    containerStyle={cx({
                                        otpInputsContainer: true,
                                        success: values.emailVerified,
                                        disabled: isFetchingVerifyCode || values.emailVerified
                                    })}
                                    inputStyle={cx({
                                        otpInput: true,
                                    })}
                                    renderInput={({className, ...inputProps}) => (
                                        <div className={cx(className)}>
                                            <input
                                                disabled={isFetchingVerifyCode || values.emailVerified} {...inputProps} />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={cx({
                                otpButtonContainer: true,
                            })}>
                                {isCodeVerified ? (
                                    <div className={cx({
                                        emailVerified: true,
                                    })}>
                                        <IconSuccess/>
                                        <span>Код подтвержден</span>
                                    </div>
                                ) : (
                                    <Button
                                        onClick={handleButtonVerifyCode}
                                        className={cx({
                                            otpButton: true,
                                        })}
                                        disabled={!isOtpFilled || isFetchingVerifyCode}
                                        type="button"
                                        size="small"
                                        styleButton="link"
                                    >
                                        Подтвердить код
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
