import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {Footer} from "./containers/Footer/Footer";
import {Survey} from "./containers/Survey/Survey";
import {Goals} from "./containers/Goals/Goals";
import {FavoriteClub} from "./containers/FavoriteClub/FavoriteClub";
import {Ticker} from "./containers/Ticker/Ticker";
import {Header} from "./containers/Header/Header";
import './styles/index.scss';

import configureStore from "./store/configureStore";
import {requestConfig} from "./store/config";
import {requestResults, requestVariant} from "./store/survey";

const store = configureStore();

const App = () => {
    store.dispatch(requestConfig());
    store.dispatch(requestResults());
    store.dispatch(requestVariant());
    return (
        <Provider store={store}>
            <Header/>
            <Ticker/>
            <FavoriteClub/>
            <Goals/>
            <Survey/>
            <Footer/>
        </Provider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);
