import React from "react";
import b from './Header.module.scss'
import winlineLogo from './assets/logo/winline-logo.png'
import winlineLogo2x from './assets/logo/winline-logo@2x.png'
import cskaLogo from './assets/logo/cska-logo.png'
import cskaLogo2x from './assets/logo/cska-logo@2x.png'
import {Headline} from "../../components/headline/Headline";
import {Button} from "../../components/button/Button";
import mainComposition from "./assets/main-composition.png";
import mainComposition2x from "./assets/main-composition@2x.png";

export const Header = () => {
    const handleClickScroll = () => {
        const element = document.getElementById('survey');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className={b.container}>
            <div className="row">
                <div className="column large-7">
                    <div className={b.logos}>
                        <a className={b.winlineLogo} target="_blank" href="https://winline.ru" rel="noreferrer">
                            <img
                                src={winlineLogo} srcSet={`${winlineLogo2x} 2x`}
                                draggable="false"
                                alt="Winline"
                            />
                        </a>
                        <a className={b.cskaLogo} target="_blank" href="https://cskabasket.ru" rel="noreferrer">
                            <img
                                src={cskaLogo} srcSet={`${cskaLogo2x} 2x`}
                                draggable="false"
                                alt="ПБК «ЦСКА»"
                            />
                        </a>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="column medium-7 large-7">
                    <div className={b.headlineContainer}>
                        <Headline className={b.headline}>
                            Ты решаешь <br className="hide-for-large"/> на что <br className="show-for-medium-only"/> потратить <br className="show-for-medium-only"/> фонд
                        </Headline>
                        <Headline className={b.headlineAccent}>
                            любимого клуба
                        </Headline>
                    </div>
                </div>
                <div className="column small-12 medium-5 large-5 position-relative">
                    <div className={b.mainComposition}>
                        <img
                            className="hide-for-medium"
                            src={mainComposition} srcSet={`${mainComposition2x} 2x`}
                            draggable="false"
                            alt=""
                        />
                    </div>
                </div>
                <div className="column small-12 large-8">
                    <div className={b.buttonContainer}>
                        <Button onClick={handleClickScroll} effect="shine" className={b.button} disabled={false} type="button" expanded={true}>
                            Принять участие в опросе
                        </Button>
                        <div className={b.buttonLabel}>
                            Голосуй ответственно! Возможно, <br/> именно твой выбор станет решающим
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
