import React from "react";
import b from './FavoriteClub.module.scss'
import {Headline} from "../../components/headline/Headline";
import {useSelector} from "react-redux";
import {getFundsRaised} from "../../store/config";
import winlineLogo from "./assets/winline-logo.png";
import winlineLogo2x from "./assets/winline-logo@2x.png";

const FundsRaised = () => {
    const fundsRaised = useSelector(getFundsRaised) || 0;
    const fundsRaisedFormatted = new Intl.NumberFormat("ru-RU",).format(
        fundsRaised,
    );
    return (
        fundsRaised === 0 ? '–' : fundsRaisedFormatted
    );
}

export const FavoriteClub = () => {
    return (
        <div className={b.container}>
            <div className={`${b.header} row`}>
                <div className="column small-10 medium-12 large-6 align-self-middle-large">
                    <Headline className={b.headline}>
                        что такое любимый клуб в приложении
                        <a target="_blank" href="https://winline.ru" rel="noreferrer">
                            <img
                                src={winlineLogo} srcSet={`${winlineLogo2x} 2x`}
                                draggable="false"
                                alt=""
                            />
                        </a>
                    </Headline>
                </div>
                <div className="column small-12 medium-10 large-5 large-offset-1">
                    <div className={b.headerText}>
                        <p>
                            Весь год болельщики, выбравшие <span className={b.highlight}>ПБК ЦСКА</span> любимым клубом
                            в приложении Winline, собирали <span className={b.highlight}>1% с каждой ставки</span>, а
                            Winline удваивал эту сумму. Вместе нам удалось накопить <span
                            className={b.highlight}><FundsRaised/> ₽!</span> Пришло время выбрать, на что их потратить!
                        </p>
                        <p>
                            Проголосовать за понравившийся вариант поддержки клуба можно не только на этом сайте, но и в
                            социальных сетях команды.
                        </p>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="column small-12">
                    <div className={b.cards}>
                        <div className={`${b.card} ${b.cardFirst}`}>
                            <Headline className={b.cardTitle}>
                                Повышенный коэффициент
                            </Headline>
                            <div className={b.cardText}>
                                Получай специальные условия ставок на все игры любимой команды
                            </div>
                        </div>
                        <div className={`${b.card} ${b.cardSecond}`}>
                            <Headline className={b.cardTitle}>
                                1% на развитие любимого клуба
                            </Headline>
                            <div className={b.cardText}>
                                Winline добавит еще 1% дополнительно к проценту от ставки болельщика
                            </div>
                        </div>
                        <div className={`${b.card} ${b.cardThird}`}>
                            <Headline className={b.cardTitle}>
                                Бонусные баллы за ставки
                            </Headline>
                            <div className={b.cardText}>
                                Получай бонусные баллы и обменивай их на подарки в приложении Winline
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
